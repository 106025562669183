import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Rx";
import "rxjs/add/operator/map";

import { ApiClient } from "./api-client";
import { Router } from "@angular/router";
import { ProjectPaginated } from "../../models/project-paginated.model";
import { Project } from "../../models/project.model";
import { Address } from "../../models/constant.objects";

@Injectable()
export class ProjectService {
  projectApiUrl = "/admin/project";
  projectApiUrlV2 = "/v2/admin/project";
  

  projectsPaginated: ProjectPaginated;

  constructor(public apiClient: ApiClient, public router: Router) { }

  getProjects(params): Observable<ProjectPaginated> {
    params = params || {};
    if (!("page" in params)) params.page = 1;

    let urlparams = Object.keys(params)
      .filter(k => params[k] != null && String(params[k]).length > 0)
      .map(k => {
        return k + "=" + encodeURIComponent(params[k]);
      })
      .join("&");

    return this.apiClient.get(`${this.projectApiUrl}?${urlparams}`).do(res => (this.projectsPaginated = res));
  }

  getProjectsForCompany(company_id: number, params): Observable<ProjectPaginated> {
    params = params || {};
    if (!("page" in params)) params.page = 1;

    let urlparams = Object.keys(params)
      .filter(k => params[k] != null && String(params[k]).length > 0)
      .map(k => {
        return k + "=" + params[k];
      })
      .join("&");

    return this.apiClient
      .get(`/admin/company/${company_id}/project?${urlparams}`)
      .do(res => (this.projectsPaginated = res));
  }

  getProjectsForCompanyV2(company_id: number, country_id?: number): Observable<any> {

    let params = '';
    if (country_id) {
      params = '?code_country_id=' + country_id;
    }
    return this.apiClient.get(`/admin/company/${company_id}/active-projects` + params);

  }

  getProjectsForContractor(user_id: number, params): Observable<ProjectPaginated> {
    params = params || {};
    if (!("page" in params)) params.page = 1;

    let urlparams = Object.keys(params)
      .filter(k => params[k] != null && String(params[k]).length > 0)
      .map(k => {
        return k + "=" + params[k];
      })
      .join("&");

    return this.apiClient
      .get(`/admin/contractor/${user_id}/project?${urlparams}`)
      .do(res => (this.projectsPaginated = res));
  }

  getProjectsForWorker(user_id: number, params): Observable<Project[]> {
    params = params || {};
    if (!("page" in params)) params.page = 1;

    let urlparams = Object.keys(params)
      .filter(k => params[k] != null && String(params[k]).length > 0)
      .map(k => {
        return k + "=" + params[k];
      })
      .join("&");

    return this.apiClient
      .get(`/admin/worker/${user_id}/project?${urlparams}`)
      .do(res => (this.projectsPaginated = res));
  }

  createProject(project: Project, hasManualAddres: boolean): Observable<Project> {
    return this.apiClient.post(`${this.projectApiUrl}?manual_address=${hasManualAddres}`, project);
  }

  getProject(id: number): Observable<Project> {
    return this.apiClient.get(`${this.projectApiUrl}/${id}`);
  }

  getProjecthoursByWeek(id: number, week: number): Observable<any> {
    return this.apiClient.get(`${this.projectApiUrl}/${id}/weekly-hours?week=${week}`);
  }

  editProject(project: Project): Observable<Project> {
    return this.apiClient.patch(`${this.projectApiUrl}/${project.id}`, project);
  }

  getProjectHoursByWeek(project_id: number, week: number): Observable<any> {
    const url = this.projectApiUrl + `/${project_id}/weekly-hours?week=${week}`;

    return this.apiClient.get(url).map((res: any) => {
      this.projectsPaginated = <ProjectPaginated>res;
      return this.projectsPaginated;
    });
  }

  getProjectHours(projectId: number, week: number): Observable<any> {
    return this.apiClient.get(
      `${this.projectApiUrlV2}/${projectId}/weekly-hours?week=${week}`
    );
  }

  archiveProject(projectId: number) {
    return this.apiClient.patch(`${this.projectApiUrl}/${projectId}/archive`, {});
  }

  editProjecPON(projectId: number, PON: string): Observable<Project> {
    return this.apiClient.patch(`${this.projectApiUrl}/${projectId}`, { purchase_order_number: PON });
  }

  editGoogleAddress(type: string, id: number, address: Address) {
    return this.apiClient.patch(`/address/${type}/${id}/update`, address);
  }

  sendHoursEmail(projectId: number, week: number) {
    return this.apiClient.post('/v2/admin/notification/send-hour-approval-email-reminder', { 'week': week, 'project_id': projectId });
  }

  editProjectUser(userId: number, params: any): Observable<Project> {
    return this.apiClient.patch(`/v2/admin/project-user/${userId}`, params);
  }
}
