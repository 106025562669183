import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import "rxjs/add/operator/map";

import { ApiClient } from "./api-client";
import { Router } from "@angular/router";
import { Request, RequestReview, RequestPricePrams, PriceData } from "../../models/request.model";
import { MatchedWorkersPaginated } from "../../models/match.model";
import { Reference } from "../../models/constant.objects";
import { WorkerPaginated } from "../../models/worker-paginated.model";
import {
  RequestPaginated,
  RequestWeeklyPaginated,
  RequestGroupWeeklyPaginated
} from "../../models/request-paginated.model";
import { RequestGroupPaginated } from "../../models/request-group-paginated.model";
import { RequestGroup } from "../../models/request-group.model";

@Injectable()
export class RequestService {
  requestApiUrl = "/admin/request";
  requestGroupApiUrl = "/admin/request-group";
  requestSegmentsApiUrl = "/admin/request-segment";
  projectApiUrl = "/admin/project";

  requestsPaginated: RequestPaginated;
  requestGroupsPaginated: RequestGroupPaginated;
  requests: Request[];

  constructor(public apiClient: ApiClient, public router: Router) { }

  getRequests(params: any): Observable<RequestPaginated> {
    params = params || {};
    if (!("page" in params)) params.page = 1;

    let urlparams = Object.keys(params)
      .filter(k => params[k] != null && String(params[k]).length > 0)
      .map(k => {
        return k + "=" + encodeURIComponent(params[k]);
      })
      .join("&");

    return this.apiClient.get(`${this.requestApiUrl}?${urlparams}`).do(res => (this.requestsPaginated = res));
  }

  getRequestGroups(params: any): Observable<RequestGroupPaginated> {
    params = params || {};
    if (!("page" in params)) params.page = 1;

    let urlparams = Object.keys(params)
      .filter(k => params[k] != null && String(params[k]).length > 0)
      .map(k => {
        return k + "=" + params[k];
      })
      .join("&");

    return this.apiClient.get(`${this.requestGroupApiUrl}?${urlparams}`).do(res => (this.requestGroupsPaginated = res));
  }

  getRequestsForCompany(company_id: number, params: any): Observable<RequestPaginated> {
    params = params || {};
    if (!("page" in params) || !params.page) params.page = 1;

    let urlparams = Object.keys(params)
      .filter(k => params[k] != null && String(params[k]).length > 0)
      .map(k => {
        return k + "=" + params[k];
      })
      .join("&");

    return this.apiClient.get(`/admin/company/${company_id}/request?${urlparams}`);
  }

  getRequestsForContractor(contractor_id: number, params: any): Observable<RequestPaginated> {
    params = params || {};
    if (!("page" in params) || !params.page) params.page = 1;

    let urlparams = Object.keys(params)
      .filter(k => params[k] != null && String(params[k]).length > 0)
      .map(k => {
        return k + "=" + params[k];
      })
      .join("&");

    return this.apiClient
      .get(`/admin/contractor/${contractor_id}/request?${urlparams}`)
      .do(res => (this.requestsPaginated = res));
  }

  getRequestsForWorker(worker_id: number, params: any): Observable<RequestPaginated> {
    params = params || {};
    if (!("page" in params) || !params.page) params.page = 1;

    let urlparams = Object.keys(params)
      .filter(k => params[k] != null && String(params[k]).length > 0)
      .map(k => {
        return k + "=" + params[k];
      })
      .join("&");

    return this.apiClient
      .get(`/admin/worker/${worker_id}/request?${urlparams}`)
      .do(res => (this.requestsPaginated = res));
  }

  getActiveRequestForWorker(worker_id: number): Observable<any> {
    return this.apiClient.get(`/v2/admin/worker/${worker_id}/request/active`).do(res => (this.requests = res.result.requests));
  }

  getRequest(id): Observable<Request> {
    return this.apiClient.get(`${this.requestApiUrl}/${id}`);
  }

  getRequestGroup(id): Observable<RequestGroup> {
    return this.apiClient.get(`${this.requestGroupApiUrl}/${id}`);
  }

  getCalendar(params: any = null): Observable<any> {
    params = params || {};
    let urlparams = Object.keys(params)
      .filter(k => params[k] != null && String(params[k]).length > 0)
      .map(k => {
        return k + "=" + params[k];
      })
      .join("&");

    return this.apiClient.get(`${this.requestApiUrl}/calendar?${urlparams}`);
  }

  getRequestGroupsCalendar(params: any = null): Observable<any> {
    params = params || {};
    let urlparams = Object.keys(params)
      .filter(k => params[k] != null && String(params[k]).length > 0)
      .map(k => {
        return k + "=" + params[k];
      })
      .join("&");

    return this.apiClient.get(`${this.requestGroupApiUrl}/calendar?${urlparams}`);
  }

  reviewWorker(user_id: number, request_id: number, review: Reference): Observable<any> {
    return this.apiClient.post(`/admin/request/${request_id}/worker/${user_id}/review`, review);
  }

  createRequest(project_id: number, requests: Request[]): Observable<any> {
    requests.map((request) => {
      if (!request.replaced_request_id) delete request.replaced_request_id;
      return request;
    }
    );

    return this.apiClient.post(`${this.projectApiUrl}/${project_id}/request`, requests);
  }

  editRequest(requestId: number, request: any): Observable<any> {
    return this.apiClient.patch(`${this.requestApiUrl}/${requestId}`, request);
  }

  getPotentialWorkers(request_id: number, params: any): Observable<WorkerPaginated> {
    params = params || {};
    let urlparams = Object.keys(params)
      .filter(k => params[k] != null && String(params[k]).length > 0)
      .map(k => {
        return k + "=" + params[k];
      })
      .join("&");
    return this.apiClient.get(`${this.requestApiUrl}/${request_id}/worker?${urlparams}`);
  }

  getRequestList(params: any) {
    params = params || {};
    let urlparams = Object.keys(params)
      .filter(k => params[k] != null && String(params[k]).length > 0)
      .map(k => {
        return k + "=" + params[k];
      })
      .join("&");

    this.apiClient.get(`${this.requestApiUrl}?${urlparams}`);
  }

  getRequestsByWeek(params: any): Observable<RequestWeeklyPaginated> {
    params = params || {};
    let urlparams = Object.keys(params)
      .filter(k => params[k] != null && String(params[k]).length > 0)
      .map(k => {
        return k + "=" + params[k];
      })
      .join("&");

    return this.apiClient.get(`${this.requestApiUrl}/weekly?${urlparams}`);
  }

  getRequestGroupsByWeek(params: any): Observable<RequestGroupWeeklyPaginated> {
    params = params || {};
    let urlparams = Object.keys(params)
      .filter(k => params[k] != null && String(params[k]).length > 0)
      .map(k => {
        return k + "=" + params[k];
      })
      .join("&");

    return this.apiClient.get(`${this.requestGroupApiUrl}/weekly?${urlparams}`);
  }

  cancelRequest(request_id: number): Observable<any> {
    let data = { request_status: { id: 7 } };
    return this.apiClient.patch(`${this.requestApiUrl}/${request_id}/cancel`, data);
  }

  cancelMultipleRequests(requestIds: any): Observable<any> {
    return this.apiClient.patch(`${this.requestApiUrl}/cancel-multiple`, requestIds);
  }

  editMultipleRequests(params: any): Observable<any> {
    return this.apiClient.patch(`${this.requestApiUrl}/edit-multiple`, params);
  }

  recordNoShow(request_segment_id: number, params: any): Observable<any> {
    return this.apiClient.post(`${this.requestSegmentsApiUrl}/${request_segment_id}/no-show`, params);
  }

  deleteNoShow(request_segment_id: number): Observable<any> {
    return this.apiClient.delete(`${this.requestSegmentsApiUrl}/${request_segment_id}/no-show`);
  }

  getWorkerReview(request_id: number, user_id: number): Observable<RequestReview> {
    return this.apiClient.get(`${this.requestApiUrl}/${request_id}/worker/${user_id}/review`);
  }

  recommendWorker(request_group_id: number, user_id: number): Observable<any> {
    return this.apiClient.patch(`${this.requestGroupApiUrl}/${request_group_id}/worker/${user_id}/recommend`, {});
  }

  unrecommendWorker(request_group_id: number, user_id: number): Observable<any> {
    return this.apiClient.patch(`${this.requestGroupApiUrl}/${request_group_id}/worker/${user_id}/unrecommend`, {});
  }

  changeHourlyRate(match_id: number, price: any): Observable<any> {
    return this.apiClient.patch(`/admin/match/${match_id}/change-hourly-rate`, { price });
  }

  delayRequest(request_id: number, start_date: any, rerun_auto_match: any): Observable<any> {
    return this.apiClient.patch(`${this.requestApiUrl}/${request_id}/delay-start-date`, {
      start_date: start_date,
      rerun_auto_match: rerun_auto_match
    });
  }

  getWorkerLink(request_group_id: number): Observable<string> {
    return this.apiClient.get(`${this.requestGroupApiUrl}/${request_group_id}/worker-link`);
  }

  // getMatchedWorkersPaginated(requestId: number): Observable<any> {
  //   return this.apiClient.get(`${this.requestApiUrl}/${requestId}/match`)
  // }
  getMatchedWorkersPaginated(requestId: number, params: any): Observable<MatchedWorkersPaginated> {
    params = params || {};
    if (!("page" in params)) params.page = 1;
    console.log("params", params);
    let urlparams = Object.keys(params)
      .filter(k => params[k] !== null && params[k].toString().length > 0)
      .map(k => {
        return k + "=" + params[k];
      })
      .join("&");

    console.log("URL", `${this.requestApiUrl}/${requestId}/match?${urlparams}`);

    return this.apiClient
      .get(`${this.requestApiUrl}/${requestId}/match?${urlparams}`)
      .do(res => (this.requestGroupsPaginated = res));
  }

  renotifyWorkers(requestId: number): Observable<any> {
    return this.apiClient.get(`${this.requestApiUrl}/${requestId}/renotify-found-workers`).map(res => res || {});
  }

  exportCSVRequestMatch(requestId: number): Observable<any> {
    return this.apiClient.get(`/admin/export/csv/request/${requestId}/match`);
  }

  updateAutoAcceptWorkers(requestId: number, autoAcceptState: boolean): Observable<any> {
    const autoAccept = { auto_accept_worker: autoAcceptState };
    return this.apiClient.patch(`${this.requestApiUrl}/${requestId}`, autoAccept);
  }

  setRequestGroupRepOwner(account_manager_id: number, request_group_id: number): Observable<any> {
    return this.apiClient.patch(`${this.requestGroupApiUrl}/${request_group_id}`, { account_manager_id });
  }

  getPrices(params: RequestPricePrams): Observable<PriceData> {
    params = params || null;

    let urlparams = Object.keys(params)
      .filter(k => params[k] != null && String(params[k]).length > 0)
      .map(k => {
        return k + "=" + params[k];
      })
      .join("&");

    return this.apiClient.get(`/v2/location-trade-pricing?${urlparams}`);
  }

  getTiersSchedule(requestId: number): Observable<any> {
    return this.apiClient.get(`/v2/admin/request/${requestId}/tiers-schedule`);
  }

  cancelRequestExtension(requestExtensionId: number): Observable<any> {
    return this.apiClient.patch(`/v2/admin/request-extension/${requestExtensionId}/cancel`, {});
  }

  getMostRecentInvoiceDate(requestId: number): Observable<any> {
    return this.apiClient.get(`/v2/admin/request/${requestId}/most-recent-uninvoiced-date`);
  }

  addAdditionalPositions(request_group_id: number, params: any): Observable<RequestGroup> {
    return this.apiClient.post(`/v2/admin/request-group/${request_group_id}/duplicate-request`, params);
  }
}
