import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Rx";
import {
  Company,
  ActiveCompanies,
  CompanyStatistics,
  ReviewedWorkerPaginated,
  CompanyRatioReviews,
  CreditCard
} from "../../models/company.model";
import { ApiClient } from "./api-client";
import { CompanyPaginated } from "../../models/company-paginated.model";
import { WorkerStatistics } from "../../models/worker.model";

@Injectable()
export class CompanyService {
  companyApiUrl = "/admin/company";
  companyApiUrlV2 = "/v2/admin/company";

  constructor(public apiClient: ApiClient) {}

  getCompanies(params: any): Observable<CompanyPaginated> {
    params = params || {};

    console.log(params);

    if (!("page" in params)) params.page = 1;

    let urlParams = Object.keys(params)
      .filter(k => params[k] != null && String(params[k]).length > 0)
      .map(k => {
        return k + "=" + encodeURIComponent(params[k]);
      })
      .join("&");

    console.log(urlParams);
    return this.apiClient.get(`${this.companyApiUrl}?${urlParams}`);
  }

  // getCompaniesV2(params: any): Observable<any> {
  //   return this.apiClient.post('/admin/company', params);
  // }

  getCompany(id: any): Observable<Company> {
    return this.apiClient.get(`${this.companyApiUrl}/${id}`);
  }

  editCompany(company_id, params): Observable<any> {
    return this.apiClient.patch(`${this.companyApiUrl}/${company_id}`, params);
  }

  getTeam(company_id, params): Observable<any> {
    params = params || {};
    if (!("page" in params)) params.page = 1;

    let urlparams = Object.keys(params)
      .filter(k => params[k] != null && String(params[k]).length > 0)
      .map(k => {
        return k + "=" + params[k];
      })
      .join("&");

    return this.apiClient.get(`${this.companyApiUrl}/${company_id}/team?${urlparams}`);
  }

  addContractor(company_id, user_id): Observable<any> {
    return this.apiClient.post(`${this.companyApiUrl}/${company_id}/contractor/${user_id}`, {});
  }

  removeContractor(company_id, user_id): Observable<any> {
    return this.apiClient.delete(`${this.companyApiUrl}/${company_id}/contractor/${user_id}`);
  }

  sendEmailInvite(email, company_id) {
    console.log("cats");
    return this.apiClient.post(`${this.companyApiUrl}/${company_id}/send-email-invite`, { email: email });
  }

  getActive(week: number, searchQuery: any ,with_unapproved_hours_count: number = 1): Observable<ActiveCompanies> {
    return this.apiClient.get(`${this.companyApiUrl}/active?week=${week}&project_name=${searchQuery.projectName}&with_unapproved_hours_count=${with_unapproved_hours_count}` + (searchQuery.repOwner != "" ? `&rep_owner_id=${searchQuery.repOwner}` : ''));
  }

  getCompanyHours(companyId: number, week: number, loggedHours: number, searchQuery: any,with_unapproved_hours_count: number = 1): Observable<any> {
    return this.apiClient.get(
      `${this.companyApiUrlV2}/${companyId}/project/weekly?week=${week}&logged_hours_only=${loggedHours}&with_unapproved_hours_count=${with_unapproved_hours_count}` + (searchQuery.projectName != "" ? `&name=${searchQuery.projectName}` : '') + (searchQuery.repOwner != "" ? `&rep_owner_id=${searchQuery.repOwner}` : '')
    );
  }

  getCompanyStatistics(companyId: number): Observable<CompanyStatistics> {
    return this.apiClient.get(`${this.companyApiUrl}/${companyId}/statistics`);
  }

  // endpoints for contractor board
  getNewCompanies(): Observable<Company[]> {
    return this.apiClient.get(`${this.companyApiUrl}/new`);
  }

  getMadeProject(): Observable<Company[]> {
    return this.apiClient.get(`${this.companyApiUrl}/made-project`);
  }

  getMadeRequest(): Observable<Company[]> {
    return this.apiClient.get(`${this.companyApiUrl}/made-request`);
  }

  getMadeRequestPreviously(): Observable<Company[]> {
    return this.apiClient.get(`${this.companyApiUrl}/made-request-previously`);
  }

  getActiveRequestNegativeExperience(): Observable<Company[]> {
    return this.apiClient.get(`${this.companyApiUrl}/active-request-negative-experience`);
  }

  getActiveProjects(company_id): Observable<Company[]> {
    return this.apiClient.get(`${this.companyApiUrl}/${company_id}/active-projects?week=0`);
  }

  getCompanyWorkers(company_id: number, params: any): Observable<ReviewedWorkerPaginated> {
    params = params || {};

    if (!("page" in params)) params.page = 1;

    let urlParams = Object.keys(params)
      .filter(k => params[k] != null && String(params[k]).length > 0)
      .map(k => {
        return k + "=" + params[k];
      })
      .join("&");

    return this.apiClient.get(`${this.companyApiUrl}/${company_id}/worker-ratings?${urlParams}`);
  }

  getCompanyRecruits(company_id: number): Observable<any> {
    return this.apiClient.get(`${this.companyApiUrl}/${company_id}/recruits`);
  }

  getCompanyPayRolls(company_id: number): Observable<any> {
    return this.apiClient.get(`${this.companyApiUrl}/${company_id}/payrolls`);
  }

  getCompanyRatingAverage(company_id: number): Observable<CompanyRatioReviews> {
    return this.apiClient.get(`${this.companyApiUrl}/${company_id}/worker-rating-average`);
  }

  updateCompanyAutoAcceptWorkers(company_id, autoAcceptState: boolean): Observable<any> {
    const autoAccept = { setting_auto_accept_worker: autoAcceptState };
    return this.apiClient.patch(`${this.companyApiUrl}/${company_id}`, autoAccept);
  }

  updateCompanyW2(company_id, w2State: boolean): Observable<any> {
    const w2 = { is_w2: w2State ? 1 : 0 };
    return this.apiClient.patch(`${this.companyApiUrl}/${company_id}/toggle-w2`, w2);
  }

  updateCompanyCreditCardRequirement(company_id): Observable<any> {
    return this.apiClient.patch(`${this.companyApiUrl}/${company_id}/toggle-credit-card-required`, {});
  }

  updateCompanyFactored(company_id): Observable<any> {
    return this.apiClient.patch(`${this.companyApiUrl}/${company_id}/toggle-factored`, {});
  }

  updateCompanySuspended(company_id): Observable<any> {
    return this.apiClient.patch(`${this.companyApiUrl}/${company_id}/toggle-suspension`, {});
  }

  setAccountManager(user_id: number, company_id: number): Observable<any> {
    return this.apiClient.patch(`${this.companyApiUrl}/${company_id}/account-manager/${user_id}`, {});
  }

  updateAccountantInfos(companyId: number, accountingInfos: any) {
    return this.apiClient.patch(`${this.companyApiUrl}/${companyId}`, accountingInfos);
  }

  updateCompanyAdditionalInvoiceEmails(companyId: number, extraEmails: any) {
    return this.apiClient.patch(`${this.companyApiUrl}/${companyId}`, extraEmails);
  }

  updateCompanyPOFrequency(companyId: number, params: any) {
    return this.apiClient.patch(`${this.companyApiUrlV2}/${companyId}/po-frequency-setting`, params);
  }

  recruitWorker(worker_id: number, company_id: number): Observable<any>{
    const params = {
      worker_id: worker_id,
      company_id: company_id
    }
    return this.apiClient.post(`/admin/recruit`, params);
  }

  addPayRolltWorker(worker_id: number, company_id: number): Observable<any>{
    const params = {
      user_id: worker_id,
      company_id: company_id
    }
    return this.apiClient.post(`/admin/payroll`, params);
  }

  removePayRoll(payRollId){
    console.log(payRollId);

    return this.apiClient.delete(`/admin/payroll/${payRollId}/remove`);
  }

  stopOrStartRecruiting(recruit_id: number, isStop: boolean): Observable<any> {
    return this.apiClient.patch(`/admin/recruit/${recruit_id}/${isStop ? 'stop': 'start'}`, {});
  }

  updateCreditCard(card: CreditCard): Observable<CreditCard> {
    return this.apiClient.post(this.companyApiUrlV2 + '/credit-card/stripe', card);
  }
}
