import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Rx";
import "rxjs/add/operator/map";
import { ApiClient } from "./api-client";
import { Router } from "@angular/router";
import { WorkerPaginated } from "../../models/worker-paginated.model";
import { Worker, WorkerStatistics, WorkerAddress, WorkerDocs } from "../../models/worker.model";
import { RequestReview, WeekDay } from "../../models/request.model";
import { TradeExperience } from "../../models/tradeExperience.model";
import { Trade, Tool } from "../../models/constant.objects";
import { WorkerReferralSource } from "../../models/referral.model";

@Injectable()
export class WorkerService {
  userApiUrl = "/admin/user";
  workerApiUrl = "/admin/worker";
  workerApiUrlV2 = "/v2/admin/worker";
  referenceUrl = "/admin/reference";
  requestUrl = "/v2/admin/request"

  workersPaginated: WorkerPaginated;
  workPermitImg: any;

  constructor(public apiClient: ApiClient, public router: Router) { }

  getWorkers(params: any): Observable<WorkerPaginated> {
    params = params || {};
    if (!("page" in params)) params.page = 1;

    let urlparams = Object.keys(params)
      .filter(k => params[k] != null && String(params[k]).length > 0)
      .map(k => {
        return k + "=" + params[k];
      })
      .join("&");

    return this.apiClient.get(`${this.workerApiUrl}?${urlparams}`).do(res => (this.workersPaginated = res));
  }

  getWorker(id: string): Observable<Worker> {
    return this.apiClient.get(this.workerApiUrl + "/" + id);
  }

  getWorkerHoursByWeek(week: number): Observable<any> {
    return this.apiClient.get(`${this.workerApiUrl}/weekly?week=${week}`);
  }

  getReviews(user_id: number): Observable<RequestReview[]> {
    return this.apiClient.get(`${this.userApiUrl}/${user_id}/review`);
  }

  deleteReview(review_id: number): Observable<RequestReview[]> {
    return this.apiClient.delete(`/admin/review/${review_id}`);
  }

  getBankAccount(user_id: number): Observable<RequestReview[]> {
    return this.apiClient.get(`${this.workerApiUrl}/${user_id}/bank-account`);
  }

  updateWorker(worker: Worker): Observable<Worker> {
    return this.apiClient.patch(`${this.workerApiUrl}/${worker.id}`, worker);
  }

  updateWorkerAddress(workerId: number, wAddress: WorkerAddress): Observable<Worker> {
    return this.apiClient.patch(`${this.workerApiUrl}/${workerId}`, wAddress);
  }

  addWorkerOrganization(workerId: number, organisations: any): Observable<Worker> {
    return this.apiClient.post(`${this.workerApiUrlV2}/${workerId}/organization`, organisations);
  }

  deleteWorkerOrganization(workerId: number, ids: any): Observable<any> {

    let qParam = "";
    ids.forEach((id) => {
      qParam = qParam + "organization_affiliation_ids[]=" + id + '&'
    })

    return this.apiClient.delete(`${this.workerApiUrlV2}/${workerId}/organization?` + qParam.toString());
  }

  updateTools(tools: Tool[], workerId: number): Observable<Worker> {
    const worker = {
      ...Worker,
      id: workerId,
      tools: tools
    };
    return this.apiClient.patch(`${this.workerApiUrl}/${workerId}`, worker);
  }

  uploadCertificate(tool_id: number, certParams: any): Observable<any> {
    return this.apiClient.post(`${this.workerApiUrlV2}/tool/${tool_id}/certification`, certParams)
  }
  banUser(id: number): Observable<Worker> {
    return this.apiClient.patch(`${this.userApiUrl}/${id}/ban`, null);
  }

  verifyWorker(worker: Worker): Observable<any> {
    return this.apiClient.patch(`${this.workerApiUrl}/${worker.id}/verify`, worker);
  }

  automatch(worker: Worker): Observable<any> {
    return this.apiClient.get(`${this.workerApiUrl}/${worker.id}/automatch`);
  }

  placeWorker(id: number, request_id: number, project_id: number, trade_experience_id: number): Observable<any> {
    let params = {
      project_id: project_id,
      request_id: request_id,
      worker_trade_experience_id: trade_experience_id
    };
    return this.apiClient.post(`${this.workerApiUrl}/${id}/match`, params);
  }

  getIntercomUser(worker_id: number): Observable<any> {
    return this.apiClient.get(`${this.userApiUrl}/${worker_id}/intercom`);
  }

  addTradeExperience(worker_id: number, trade: Trade, tradeExperience: TradeExperience): Observable<any> {
    let params = {
      user_id: worker_id,
      trade: trade,
      trade_experience: tradeExperience
    };
    return this.apiClient.post(`${this.workerApiUrl}/trade_experience`, params);
  }

  deleteTradeExperience(id: number): Observable<any> {
    return this.apiClient.delete(`${this.workerApiUrl}/trade_experience/${id}`);
  }

  // Reference methods
  addReference(reference: any): Observable<any> {
    return this.apiClient.post(`${this.referenceUrl}`, reference);
  }

  updateReference(reference: any): Observable<any> {
    return this.apiClient.patch(`${this.referenceUrl}/${reference.id}`, reference);
  }

  deleteReference(referenceId: number): Observable<any> {
    return this.apiClient.delete(`${this.referenceUrl}/${referenceId}`);
  }

  getWorkerStatistics(workerID: number): Observable<WorkerStatistics> {
    return this.apiClient.get(`${this.workerApiUrl}/${workerID}/statistics`);
  }

  getWorkerJobHistory(workerID: number): Observable<any> {
    return this.apiClient.get(`${this.workerApiUrl}/${workerID}/rating`);
  }

  addToBlackList(workerID: number, companyID: number): Observable<any> {
    return this.apiClient.post(`${this.workerApiUrl}/${workerID}/company/${companyID}/blacklist`, {});
  }

  blackListWorker(workerID: number, expiresAt: string, notes: string): Observable<any> {
    const blackListParams = {
      expires_at: expiresAt,
      notes: notes
    }
    return this.apiClient.post(`/v2${this.workerApiUrl}/${workerID}/blacklist`, blackListParams);
  }

  removeCompanyFromBlackList(workerID: number, companyID: number): Observable<any> {
    return this.apiClient.delete(`${this.workerApiUrl}/${workerID}/company/${companyID}/blacklist`);
  }

  editWorkerReferralSource(workerID: number, referralSource: WorkerReferralSource): Observable<any> {
    return this.apiClient.post(`${this.userApiUrl}/${workerID}/referral`, referralSource);
  }

  editWorkerAvailability(workerID: number, weekdays: WeekDay[]): Observable<any> {
    return this.apiClient.patch(`${this.workerApiUrl}/${workerID}`, { weekdays: weekdays });
  }

  getWorkerDocs(workerId: number): Observable<WorkerDocs> {
    return this.apiClient.get(`${this.workerApiUrl}/${workerId}/work-permit`);
  }

  getWorkerDocuments(workerId: number): Observable<any> {
    return this.apiClient.get(`${this.workerApiUrlV2}/${workerId}/documents`);
  }

  getWorkPermitImg(workerId: number): Observable<WorkerDocs> {
    return this.apiClient
      .get(`${this.workerApiUrl}/${workerId}/work-permit-image`)
      .do(res => (this.workPermitImg = res.result));
  }

  saveWorkerDocs(workerId, workerDocs): Observable<any> {
    return this.apiClient.post(`${this.workerApiUrl}/${workerId}/work-permit`, workerDocs);
  }

  updateWorkPermitImg(workerId, image: File): Observable<any> {
    let workPermit = new FormData();
    workPermit.append("image", image as File);

    return this.apiClient.post(`${this.workerApiUrl}/${workerId}/work-permit-image?type=file`, workPermit);
  }

  updateWorkerW2(workerId: number, w2State: boolean): Observable<any> {
    const w2 = { is_w2: w2State ? 1 : 0 };
    return this.apiClient.patch(`${this.userApiUrl}/${workerId}/toggle-w2`, w2);
  }

  boostWorkerQualityScore(workerId, reason, dimensions, suggestedScore): Observable<any> {
    const attributes = {
      reason,
      attributes: dimensions,
      suggested_score: suggestedScore
    }
    return this.apiClient.patch(`${this.workerApiUrl}/${workerId}/worker-quality-score/boost`, attributes);
  }
  getWorkerSkills(userId: number): Observable<any> {
    return this.apiClient.get(`${this.workerApiUrl}/${userId}/request/history`);
  }

  verifyWokerSkills(skillLogs: any): Observable<any> {
    return this.apiClient.patch(`/admin/worker-skill-log/verification-multiple`, skillLogs);
  }

  getWorkerTradeSkills(userId: number, tradeId: number, expId: number) {
    return this.apiClient.get(`${this.workerApiUrl}/${userId}/trade/${tradeId}/skill?code_experience_id=${expId}`);
  }

  getSkillEndorsement(userId: number, skillId: number) {
    return this.apiClient.get(`${this.workerApiUrl}/${userId}/skill/${skillId}`);
  }

  getTodaysWorkers(date: string) {
    return this.apiClient.get(`${this.requestUrl}/daily?date=${date}`);
  }

  moveWorkerOrUpdatePrice(data: any): Observable<any> {
    return this.apiClient.post(`${this.workerApiUrlV2}/move-or-update-price`, data);
  }

  getWorkerWeeklyHours(workerId: number, projectId: number, week: number): Observable<any> {
    return this.apiClient.get(
      `${this.workerApiUrlV2}/${workerId}/weekly-hours?week=${week}&project_id=${projectId}`
    );
  }
}
