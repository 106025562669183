import { ApiClient } from './api-client';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { Hour } from '../../models/hour.model';
import { Project } from '../../models/project.model';
import { HttpHeaders } from '@angular/common/http';


@Injectable()
export class HourService {

    private workerApiUrl = '/admin/worker';
    private workerApiUrlV2 = '/v2/admin/worker';
    private hourApiUrl = '/admin/hour';
    private hourApiUrlV2 = '/v2/admin/hour';
    private workerContractorApiUrl = '/admin/worker/contractor';
    private paymentsApiUrl = '/admin/payments';

    constructor(private apiClient: ApiClient) { }

    getMasterHours(week: number, logged_hours_only): Observable<any> {

        return this.apiClient.get(`${this.workerApiUrl}/weekly?week=${week}&logged_hours_only=${logged_hours_only}`);
    }

    getHour(id: string): Observable<any> {
        return this.apiClient.get(`${this.hourApiUrl}/${id}`);
    }

    createHour(contractor_id: number, hour: Hour): Observable<any> {
        return this.apiClient.post(`${this.workerContractorApiUrl}/${contractor_id}/hour`, hour);
    }

    editHour(hour: any): Observable<any> {
        return this.apiClient.patch(`${this.workerApiUrl}/hour/${hour.id}`, hour);
    }

    editManyHours(hours: any[]): Observable<any> {
        return this.apiClient.patch(this.hourApiUrl, hours);
    }

    moveHour(request_segment_id: number, param: any): Observable<any> {
        return this.apiClient.patch(`${this.hourApiUrl}/${request_segment_id}/move`, param)
    }

    markAsPaid(request_segment_id: number, param: any = {}): Observable<any> {
        return this.apiClient.patch(`${this.workerApiUrlV2}/hour/${request_segment_id}/worker-paid`, param)
    }

    requestInstantPay(id: number) {
        return this.apiClient.patch(`${this.hourApiUrlV2}/${id}/instant-pay/request`, {});
    }

    removeInstantPay(id: number) {
        return this.apiClient.patch(`${this.hourApiUrlV2}/${id}/instant-pay/remove`, {});
    }

    approveAllHours(param: any) {
        return this.apiClient.patch('/v2/admin/request_segment/approve-all', param);
    }

    recalculateHour(id: number): Observable<any> {
        return this.apiClient.patch(`/v2/admin/worker/hour/${id}/recalculate`, {});
    }

    forceDeleteHour(id: number): Observable<any> {
        return this.apiClient.delete(`/v2/admin/worker/hour/${id}/force-delete`);
    }

    markWorkerAsLate(data: any) {
        return this.apiClient.post(`/admin/worker/late-withdraw`, data);
    }

    deleteWorkerWithdraw(id: number): Observable<any> {
        return this.apiClient.delete(`/v2/admin/worker-cancellation/${id}`);
    }

    reviseSkillHours(id: number, skillsData: any){
        return this.apiClient.patch(`/v2/admin/request-segment/${id}/skill-tags`, skillsData);
    }

}

